import Axios from 'axios';
import HMAC from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8'
import moment from 'moment';

import * as CL from '../../const/currency';

const getEndpoint = (slot, uri) => {
  return ('http://api-' + String(slot) + '.tradesharp.io:2052/api/' + uri);
}

export const getAccountBalance = async (slot, ticker, con, sec) => {
  let data = {
    "ticker": ticker,
    "connect": con,
    "secret": sec,
  }
  let result;
  let resultFormat = {
    total: null,
    inuse: null,
    avail: null
  }
  
  try {
    //result = await Axios.get("http://tradesharp.io:2052/api/getbalance", {params : data})
    result = await Axios.get(getEndpoint(slot, 'getbalance'), {params : data})
  } catch(err) {
    return false;
  }

  //console.log(result.data);

  //total_
  //in_use_
  //available_
  //xcoin_last_
  let tickerList = Object.keys(result.data.data);
  let tmpResult = {};

  if(result.data.status === "0000") {
    for(let i = 0; i < CL.tickers.length; i++) {
      tmpResult[CL.tickers[i]] = {
        total: result.data.data["total_" + CL.tickers[i]],
        inuse: result.data.data["in_use_" + CL.tickers[i]],
        avail: result.data.data["available_" + CL.tickers[i]],
        price: result.data.data["xcoin_last_" + CL.tickers[i]]
      }
    }
  
    return ({
      data: tmpResult,
      time: moment().format()
    });
  } else {
    return false;
  }
  /*
    result.data.(ticker).balance : balance
    result.data.(ticker).avail : available
  */
}

export const getOrderbook = async (slot, pair) => {
  let result;
  try {
    result = await Axios.get(getEndpoint(slot, 'getorderbook'), {params : {'pair': pair}})
  } catch(err) {
    return false;
  }

  return result.data.data;
}

export const getOpenOrders = async (slot, orderCurrency, paymentCurrency, con, sec) => {
  //array type
  // [ordercurrency, paymentcurrency, units, price, type(bid/ask)]

  let data = {
    "orderCurrency": orderCurrency,
    "paymentCurrency": paymentCurrency,
    "connect": con,
    "secret": sec,
  }
  let result;
  let resultFormat = {
    orders: [],
  }
  
  try {
    result = await Axios.get(getEndpoint(slot, 'getopenorders'), {params : data})
  } catch(err) {
    return false;
  }

  //console.log(result.data);

  if(result.data.status === "0000") {
    /*
    for(let i = 0; i < result.data.data.length; i++) {
      console.log(result.data.data[i])
    }
    */
  
    return ({
      data: result.data.data,
      time: moment().format()
    });
  } else {
    return false;
  }
}

export const getTradeHistory = async (slot, historyReqObj, con, sec) => {
  //array type
  // [ordercurrency, paymentcurrency, units, price, type(bid/ask)]
  //getAccountTradeHistory(req.query.offset, req.query.count, req.query.mode, req.query.orderCurrency, req.query.paymentCurrency);

  let data = {
    //"orderCurrency": orderCurrency,
    //"paymentCurrency": paymentCurrency,
    "offset": historyReqObj.offset,
    "count": historyReqObj.count,
    "mode": historyReqObj.mode,
    "orderCurrency": historyReqObj.orderCurrency,
    "paymentCurrency": historyReqObj.paymentCurrency,
    "connect": con,
    "secret": sec,
  }
  let result;

  try {
    result = await Axios.get(getEndpoint(slot, 'gettradehistory'), {params : data})
  } catch(err) {
    console.log(err);
    return false;
  }

  if(result.data.status === "0000") {
    return ({
      data: result.data.data,
      time: moment().format()
    });
  } else {
    return false;
  }
}

export const placeOrder = async (slot, orderInfo, con, sec) => {
  //array type
  //[ordercurrency, paymentcurrency, units, price, type(bid/ask)]

  let data = {
    "orderInfo": orderInfo,
    "connect": con,
    "secret": sec,
  }
  let result;
  let resultFormat = {
    order_id: null,
  }
  
  try {
    result = await Axios.post(getEndpoint(slot, 'placeorder'), data)
  } catch(err) {
    return false;
  }

  if(result.data.status === "0000") {
    return ({
      data: result.data,
      time: moment().format()
    });
  } else {
    return false;
  }
}

export const cancelOrder = async (slot, orderInfo, con, sec) => {
  //array type
  //[ordercurrency, paymentcurrency, orderid, type(bid/ask)]

  let data = {
    "orderInfo": orderInfo,
    "connect": con,
    "secret": sec,
  }
  let result;
  let resultFormat = {
    order_id: null,
  }
  
  try {
    result = await Axios.post(getEndpoint(slot, 'cancelorder'), data)
  } catch(err) {
    return false;
  }

  if(result.data.status === "0000") {
    return ({
      data: result.data,
      time: moment().format()
    });
  } else {
    return false;
  }

  /*
  let tickerList = Object.keys(result.data.data);
  let tmpResult = {};

  if(result.data.status === "0000") {
    for(let i = 0; i < CL.tickers.length; i++) {
      tmpResult[CL.tickers[i]] = {
        total: result.data.data["total_" + CL.tickers[i]],
        inuse: result.data.data["in_use_" + CL.tickers[i]],
        avail: result.data.data["available_" + CL.tickers[i]],
        price: result.data.data["xcoin_last_" + CL.tickers[i]]
      }
    }
  
    console.log(tmpResult);

    return ({
      data: tmpResult,
      time: moment().format()
    });
  } else {
    return false;
  }
  */
}