import React from 'react';
import './ts-textarea.css';

//to-do : add state-based input data mode for future fatures.

function TSTextarea (props) {
	return(
    <div className='ts-textarea'>
      <textarea disabled={props.isDisabled} autoComplete='off' value={props.content}/>
    </div>
  )
}

TSTextarea.defaultProps = {
  isDisabled: false,
  content: "NO CONTENT",
}

export default TSTextarea;