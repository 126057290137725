import React, { Component } from 'react';

import TSModule from "../../UI/atoms/ts-module/ts-module";
import TSInput from '../../UI/atoms/ts-input/ts-input';
import TSButton from '../../UI/atoms/ts-btn-simple/ts-btn-simple';

import './ts-page-home.css';

class TSHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      keyInput: ""
    }
  }

  handleActivationKeyWork = (op) => {
    if(op === "ENT") {
      localStorage.setItem("6326A401", this.state.keyInput);
    } else if (op === "DEL") {
      localStorage.setItem("6326A401", "");
    } else if (op === "DEL_ALL") {
      localStorage.clear();
    }

    location.reload();
  }

  inputHandler = (e) => {
    this.setState({
      keyInput: e.target.value
    })
  }

  render = () => {
    let tmpDiv =
    <div>
      <TSInput inputHandler={this.inputHandler}/>
      <br/>
      <TSButton theme="blue" label="Enter Activation Key" clickHandler={()=>{this.handleActivationKeyWork("ENT")}}/>
      <br/><br/>
      <TSButton theme="dark" label="⚠️Delete Activation Key" clickHandler={()=>{this.handleActivationKeyWork("DEL")}}/>
      <br/><br/>
      <TSButton theme="dark" label="⚠️CLEAR ALL" clickHandler={()=>{this.handleActivationKeyWork("DEL_ALL")}}/>
    </div>

    return (
      <div className="ts-page-home-container">
        <div className="ts-page-home-content">
          <TSModule label="Welcome to Tradesharp v3.0" content={null}/>
        </div>
        <div className="ts-page-home-control">
          <TSModule noLabel label=":D" content={tmpDiv}/>
        </div>
      </div>
    ) 
  }
}

export default TSHome;