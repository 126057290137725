import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Hotkeys from 'react-hot-keys';

//import axios from 'axios';
//import * as config from './const/api';

import { TSHome, TSSelect, TSDetail, TSHistory, TSFeed, TSTester } from "./components/routes";
import Sidemenu from "./components/UI/organisms/ts-sidemenu/ts-sidemenu.js";

import "./App.css";

class App extends Component {

  detectMobile = (agent) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ]

    return mobileRegex.some(mobile => agent.match(mobile))
  }
  isMobile = this.detectMobile(window.navigator.userAgent); //true if this device is mobile


  constructor(props) {
    super(props);

    this._isMounted = false;
    // shoud setup account-related features here (initialize);

    try {
      localStorage.getItem("6326A401")
    } catch (err) {
      localStorage.setItem("6326A401", "")
    }
    
    this.state = {
      showSideMenu: true
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.source.cancel('Operation canceled by the user.');
  }

  //ADD default route notfound!!!!!!!!


  onKeyUp(keyName, e, handle) {
    //
  }
  onKeyDown(keyName, e, handle) {
    console.log("Executing Shortcut from Master layer: " + keyName);

    if (keyName === "shift+a") this.setState({
      showSideMenu: !this.state.showSideMenu
    });
    else return;
  }

  render() {
    //connect mobile website
    /*
    if (this.isMobile) {
      return (
        <div className='m-root-div'>
          mobile updating soon
        </div>
      );
    }
    */

    //connect pc website
    //accountmanager at the top level
    //180 + 40 + 1200 + 20 + 360 = 1800

    /*
              <Switch>
                <Route exact path="/" component={Home} />
              </Switch>
    */
    //tsmodule for basic boxes

    if (localStorage.getItem("6326A401") !== "20220715_a31F4341_e98948D2") { //filter activation key
      console.log("BONK");
      return (
        <TSHome />
      )
    } else {
      return (
        <div className='root-div'>

          <link href="https://fonts.googleapis.com/css?family=Atkinson+Hyperlegible|Roboto+Mono|Montserrat:700|Montserrat:500|Montserrat:300&display=swap" rel="stylesheet" />
          <link href='//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css' rel='stylesheet' type='text/css' />

          <Hotkeys
            keyName="shift+a"
            onKeyDown={this.onKeyDown.bind(this)}
            onKeyUp={this.onKeyUp.bind(this)}>

            <Router>
              <div className='page-div'>
                { this.state.showSideMenu ? <div className="sidemenu-container"> <Sidemenu /> </div> : null }

                <div className="page-container">
                  <Route exact path="/" component={TSHome} />
                  <Route exact path="/ts3/select" component={TSSelect} />
                  <Route exact path="/ts3/detail" component={TSDetail} />
                  <Route exact path="/ts3/history" component={TSHistory} />
                  <Route exact path="/ts3/feed" component={TSFeed} />

                  <Route exact path="/ts3/tester" component={TSHome} />
                  <Route exact path="/ts3/automator" component={TSHome} />
                </div>

              </div>
            </Router>
          </Hotkeys>

        </div>
      );
    }
  }
}

export default App;
