import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSCardPricefeed from '../../atoms/ts-card-pricefeed/ts-card-pricefeed';

import "./ts-feed-content-list.css";

class FeedContentList extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    let cardList = [];

    for(let i = 0; i < this.props.tickers.length; i++) {
      cardList.push(
        <TSCardPricefeed
          headerData={{"exchange" : "Bithumb", "ticker" : this.props.tickers[i], "payment" : "KRW"}}
          tickerData={this.props.tickersData[i]}
          statusData={this.props.statusData[i]}
        />
      )
    }

    const finalContent = (
      <div className='ts-feed-content-list'>
        {cardList}
      </div>
    )

    //modulize and return

    return (
      <TSModule
        label="Price Feed"
        content={finalContent}
      />
    )
  }
}

FeedContentList.defaultProps = {
  tickers: [],
  tickersData: [],
  statusData:  []
}

export default FeedContentList;