import React from 'react';
import { Link, withRouter } from "react-router-dom";

import TSBtnSidemenu from "../../atoms/ts-btn-sidemenu/ts-btn-sidemenu";

import './ts-sidemenu-nav.css';

function TSSidemenuNav (props) {
  let sidemenuList = [];

  for(let i = 0; i < props.navData.length; i++) {
    sidemenuList.push(
      <Link to={props.navData[i].path}>
        <TSBtnSidemenu isSelected={props.navData[i].id === props.selectedMenu} sidemenuID={String(props.navData[i].id)} sidemenuLabel={props.navData[i].label}/>
      </Link>
    )
  }

	return (
    <div className="ts-sidemenu-nav">
      {sidemenuList}
    </div>
  )
}

export default withRouter(TSSidemenuNav);