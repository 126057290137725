import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';
import TSLabelSimple from '../../atoms/ts-label-simple/ts-label-simple';

import "./ts-accountdetail-control-call.css";

class AccountdetailControlCall extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const finalContent = (
      <div className='ts-account-detail-control-call'>
        <br/>
        <TSLabelSimple theme="dark" labelText={"Available Call " + this.props.avail + " / 30"}/><br/>
        <TSLabelSimple theme="dark" labelText={"Auto-update " + (this.props.isAutoUpdateOn ? "ON" : "OFF") +""}/><br/>

        <TSButton theme="blue" label="RELOAD ALL" clickHandler={()=>{this.props.updater(-1)}}/>
        <br/><br/>
        <TSButton theme="dark" label={("TURN " + (this.props.isAutoUpdateOn ? "OFF" : "ON") + " AUTO UPDATE")} clickHandler={()=>{this.props.autoUpdater()}}/>
      </div>
    )

    //modulize and return
    return (
      <TSModule
        label="Controller"
        content={finalContent}
      />
    )
  }
}

AccountdetailControlCall.defaultProps = {
  updater: () => {},
  autoUpdater: () => {},
  avail: 0,
  isAutoUpdateOn: false,
}

export default AccountdetailControlCall;