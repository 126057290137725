import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSTableHistory from '../../atoms/ts-table-history/ts-table-history';

import "./ts-history-content-list.css";

class HistoryContentList extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const finalContent = (
      <div className='ts-history-content-list'>
        <TSTableHistory tradehistoryList={this.props.tradehistoryList}/>
      </div>
    )

    //modulize and return
    return (
      <TSModule
        label={"Trade History List [Page " + String(this.props.tradehistoryPage) + "]"}
        content={finalContent}
      />
    )
  }
}

HistoryContentList.defaultProps = {
  tradehistoryList: [],
  tradehistoryPage: 0
}

export default HistoryContentList;