import React, { Component } from 'react';

import TSDropdown from '../../atoms/ts-dropdown/ts-dropsown';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';

import { tickers } from '../../../../const/currency';

import './ts-history-pairselector.css';

class TSHistoryPairselector extends Component {

  constructor(props) {
    super(props);

    let pairList = [
      {
        type: 'group', name: 'In Balance', items: [
          { value: 'APM', label: 'APM' },
          { value: 'XRP', label: 'XRP' }, // className: 'myOptionClassName' for custom
          { value: 'PCI', label: 'PCI' }
        ]
      },
      {
        type: 'group', name: 'All (Major)', items: []
      },
      {
        type: 'group', name: 'All (Etc.)', items: []
      }
    ]
    let pairPaymentList = [
      "KRW", "BTC"
    ]

    let tmpTickers = JSON.parse(JSON.stringify(tickers)); //deep copy
    for(let i = 0; i < 10; i++) {
      pairList[1].items.push({value: String(tmpTickers[0]).toUpperCase(), label: String(tmpTickers[0]).toUpperCase()})
      tmpTickers.shift();
    }
    tmpTickers.sort();
    for(let i = 0; i < tmpTickers.length; i++) {
      pairList[2].items.push({value: String(tmpTickers[i]).toUpperCase(), label: String(tmpTickers[i]).toUpperCase()})
    }

    this.state = {
      pairList: pairList,
      pairPaymentList: pairPaymentList,
      orderCurrency: "BTC",
      paymentCurrency: "KRW",
    }
  }

  selectHandler = (e, id) => {
    if(id === "order") {
      this.setState({
        orderCurrency: e.value
      })
    }
    else if(id === "payment") {
      this.setState({
        paymentCurrency: e.value
      })
    }
  }

  render() {
    return (
      <div className="ts-history-pairselector">
        <div className="ts-history-pairselector-pairs">
          <div className='ts-history-pairselector-pairs-1'>
            <TSDropdown options={this.state.pairList}        selectHandler={(e) => {this.selectHandler(e, "order")}} value={this.state.pairList[1].items[1]}/>
          </div>
          <div className='ts-history-pairselector-pairs-2'>
            <TSDropdown options={this.state.pairPaymentList} selectHandler={(e) => {this.selectHandler(e, "payment")}} disabled/>
          </div>
        </div>
        <div className="ts-history-pairselector-buttons">
          <TSButton theme="blue" label="SELECT PAIR & RELOAD" clickHandler={()=>{this.props.submitHandler([this.state.orderCurrency, this.state.paymentCurrency])}}/>
        </div>
      </div>
    )
  }
}

TSHistoryPairselector.defaultProps = {
  submitHandler: () => {}
}

export default TSHistoryPairselector;