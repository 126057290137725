import React from 'react';
import './ts-label-simple.css';

function TSLabelSimple (props) {

  // Props
  // theme : string - ('light' : white background/blue text, 'dark' : dark background/white text)
  // labelText : string - text displayed

  return (
    <div className={"ts-label-simple ts-label-simple-" + props.theme + (props.adaptive ? " ts-label-simple-adaptive" : "")}>
      <p>{props.labelText}</p>
    </div>
  )
}

TSLabelSimple.defaultProps = {
  adaptive: false,
  theme: 'light',
  labelText: ""
}

export default TSLabelSimple;