import React from 'react';

import TSTable from '../../atoms/ts-table/ts-table';
import TSLabelSimple from '../../atoms/ts-label-simple/ts-label-simple';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';

import './ts-accountdetail-card.css';

const renderNumber = (num, digit) => {
  let tmpDigit = 8;
  if(digit == null) tmpDigit = 8;
  else tmpDigit = digit;
  return Number(num).toLocaleString('en-US', {minimumFractionDigits: tmpDigit, maximumFractionDigits: tmpDigit});
}

function TSAccountDetailCard (props) {
  let processedAssetList= [];
  for(let i = 0; i < props.assetList.length; i++) {
    //[{"ticker":"xrp","total":"2121.92153519","inuse":"0.00000000","avail":"2121.92153519","price":"450.3"}]

    processedAssetList.push({
      isUpdated: props.deltaList[i],
      ticker: props.assetList[i].ticker,
      balance: renderNumber(props.assetList[i].total, 4),
      avail: renderNumber(props.assetList[i].avail, 4),
      worth: renderNumber(Number(props.assetList[i].total) * Number(props.assetList[i].price), 0),
      price: renderNumber(props.assetList[i].price, 2),
      abp: renderNumber(props.assetList[i].abp, 2)
    })
  }

	return (
    <div className='ts-accountdetail-card'>
      <div className='ts-accountdetail-card-labelarea'>
        <div className='ts-accountdetail-card-labelarea-texts'>
          <p>
            <span>[SLOT 0{props.slotId}] </span>
            &nbsp;
            {props.nick}
            &nbsp;&nbsp;&nbsp;
            <span className='ts-accountdetail-card-labelarea-texts-time'>(Updated at : {props.timestamp})</span>
          </p>
        </div>
        { props.isMain ? <div className='ts-accountdetail-card-labelarea-buttons'> <TSLabelSimple adaptive theme="transparent" labelText="✔️ MAIN"/></div> : null }
        <div className='ts-accountdetail-card-labelarea-buttons'>
          <TSButton adaptive theme="darkblue" label="Reload" clickHandler={()=>{props.updater(props.slotId)}}/>
        </div>
      </div>

      <div className='ts-accountdetail-card-listarea'>
        <TSTable dataList={processedAssetList}/>
      </div>
    </div>
  )
}

TSAccountDetailCard.defaultProps = {
  slotId: 0,
  nick: "NO ACCOUNT",
  timestamp: "2022-00-00 T09:00:00",
  isMain: false,
  assetList: [{}],
  deltaList: [],
  updater: () => {}
}

export default TSAccountDetailCard;