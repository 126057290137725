import React from 'react';

//import { Grid } from 'gridjs-react';
//import ChangeHighlight from "react-change-highlight";

import './ts-table.css';

function TSTable (props) {

  let tmpList = [];
  for(let i = 0; i < props.dataList.length; i++) {
    //normal, normal-updated, odd, odd-updated

    let trClassName = ""; //even and not updated
    if(i % 2 === 0 && props.dataList[i].isUpdated)       trClassName="ts-table-tr-updated";     //even and updated
    else if(i % 2 !== 0 && props.dataList[i].isUpdated)  trClassName="ts-table-tr-odd-updated"; //odd and updated
    else if(i % 2 !== 0 && !props.dataList[i].isUpdated) trClassName="ts-table-tr-odd";         //odd and not updated

    tmpList.push(
      <tr className={trClassName} key={props.dataList[i].ticker}>
        <td className='ts-table-r1 ts-table-text ts-table-text-td'>{String(props.dataList[i].ticker).toUpperCase()}</td>
        <td className='ts-table-r2'>{props.dataList[i].balance}</td>
        <td className='ts-table-ra'><span>({props.dataList[i].avail})</span></td>
        <td className='ts-table-r3'>{props.dataList[i].worth}</td>
        <td className='ts-table-r4'>{props.dataList[i].price}</td>
        <td className='ts-table-r5'>{props.dataList[i].abp}</td>
      </tr>
    )
  }

	return (
    <div className='ts-table-wrapper'>
      <table className='ts-table'>
        <thead>
          <tr className='ts-table-tr-odd ts-table-text' key="head">
            <th className='ts-table-r1'>Ticker</th>
            <th className='ts-table-r2'>Balance</th>
            <th className='ts-table-ra'>Avail.</th>
            <th className='ts-table-r3'>Balance Worth (KRW)</th>
            <th className='ts-table-r4'>Market Price</th>
            <th className='ts-table-r5'>Avg. Buy Price</th>
          </tr>
        </thead>
        <tbody>
          {tmpList}
        </tbody>
      </table>
    </div>
  )
}

TSTable.defaultProps = {
  dataList: [{
    isUpdated: false,
    ticker: "KRW",
    balance: 0,
    avail: 0,
    worth: 0,
    price: 0,
    abp: 0
  }]
}

export default TSTable;