import React from 'react';
import './ts-btn-sidemenu.css';

function TSBtnSidemenu (props) {

  // Props
  // isSelected : boolean - if true displays selected page
  // sidemenuID : string - used to find icons
  // sidemenuLabel: string - used to label

  return (
    <div className={"ts-btn-sidemenu " + (props.isSelected ? "ts-btn-sidemenu-selected" : "")}>
      <img className="ts-btn-sidemenu-icon" src={require("../../../../asset/icons/sidemenu-nav-" + (props.isSelected ? "selected-" : "") + props.sidemenuID + ".svg")} alt="ICON"/>
      <p>{props.sidemenuLabel}</p>
    </div>
  );
}

export default TSBtnSidemenu;