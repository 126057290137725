import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';
import TSTextarea from '../../atoms/ts-textarea/ts-textarea';

import "./ts-history-content-log.css";

class HistoryContentLog extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const finalContent = (
      <div className='ts-history-content-log'>
        <div className='ts-history-content-logbox'>
          <TSTextarea content={this.props.logData} isDisabled={true}/>
        </div>
        <TSButton label="CLEAR" clickHandler={this.props.logClear}/>
      </div>
    )

    //modulize and return
    return (
      <TSModule
        label="Trade History Log"
        content={finalContent}
      />
    )
  }
}

HistoryContentLog.defaultProps = {
  logData: "",
  logClear: () => {}
}

export default HistoryContentLog;