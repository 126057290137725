import React from 'react';
import { Link, withRouter } from "react-router-dom";

import TSCardAccount from '../../atoms/ts-card-account/ts-card-account';

import './ts-account-list-selector.css';

function TSAccountListSelector (props) {
  let accounts = []
  for (let i = 0; i < props.accountListData.length; i++) {
    accounts.push(
      <TSCardAccount accountData={props.accountListData[i]} selectAccountHandler={props.selectAccountHandler} selectMainHandler={props.selectMainHandler}/>
    )
  }

	return (
    <div className="ts-account-list-selector">
      {accounts}
    </div>
  )
}

TSAccountListSelector.defaultProps = {
  accountListData: [],
  selectAccountHandler: (e) => {console.log(e)},
  selectMainHandler: (e) => {console.log(e)}
}

export default withRouter(TSAccountListSelector);