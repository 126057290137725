import React from 'react';
import './ts-input.css';

//to-do : add state-based input data mode for future fatures.

function TSInput (props) {
	if(props.isActive) { //state-based render
    return (
      <div className='ts-input'>
        {props.label === "" ? null : <p>{props.label}</p>}
        <input name={props.name} value={props.value} onInput={props.inputHandler}/>
      </div>
    )
  } else {
    return (
      <div className='ts-input'>
        {props.label === "" ? null : <p>{props.label}</p>}
        <input name={props.name} onInput={props.inputHandler}>
        
        </input>
      </div>
    )
  }
}

TSInput.defaultProps = {
  label: "",
  name: "",
  isActive: false,
  value: "",
  inputHandler: () => {}
}

export default TSInput;