export const getAccounts = () => {
  let accounts = [];

  for (let i = 0; i < 10; i++) {
    let tmpAccount = localStorage.getItem("acc" + String(i));
    if (tmpAccount == null) {
      accounts.push({
        slotId: i,
        con: "00000000000000000000000000000000",
        sec: "00000000000000000000000000000000",
        nick: "NO ACCOUNT"
      })
    } else {
      accounts.push(JSON.parse(tmpAccount))
    }
  }
  return accounts;
}

export const setAccount = (index, data) => {
  localStorage.setItem("acc" + String(index), JSON.stringify(data));

  return true;
}

export const setMainAccountNumber = (number) => {
  localStorage.setItem("mainAccount", String(number));
}

export const getMainAccount = () => {
  let tmpNumber = localStorage.getItem("mainAccount");

  if (tmpNumber == null) {
    localStorage.setItem("mainAccount", "0");
    tmpNumber = 0;
  }
  else tmpNumber = Number(tmpNumber);

  let tmpAccount = localStorage.getItem("acc" + String(tmpNumber));

  if (tmpAccount == null) {
    return {
      slotId: 0,
      con: "00000000000000000000000000000000",
      sec: "00000000000000000000000000000000",
      nick: "NO ACCOUNT"
    }
  } else {
    return JSON.parse(tmpAccount)
  }
}

export const getMainAccountNumber = () => {
  let tmpNumber = localStorage.getItem("mainAccount");

  if (tmpNumber == null) {
    localStorage.setItem("mainAccount", "0");
    return 0;
  }
  else return Number(tmpNumber);
}


/* AVG. buy price area */
/*
abp data structure
localstorage id : accabp0 ~ 9
localstorage data : {slotId: 0, abpList: {ticker: {abp: 0, amt: 0, timestamp: 0}}}
*/

export const getAccountABP = (slotId) => {
  let tmpAccountABP = JSON.parse(localStorage.getItem("accabp" + String(slotId)));
  console.log(tmpAccountABP);
  console.log(slotId);

  if (tmpAccountABP === null) {
    return false;
  } else {
    return(tmpAccountABP);
  }
}

export const getAccountTickerABP = (slotId, ticker) => {
  ticker = String(ticker).toLowerCase();
  let tmpAccountABP = JSON.parse(localStorage.getItem("accabp" + String(slotId)));

  if (tmpAccountABP === null) {
    return false;
  } else {
    let tmpKeys = Object.keys(tmpAccountABP.abpList);
    //console.log(tmpKeys);
    //console.log(tmpKeys.indexOf(ticker));

    if(tmpKeys.indexOf(ticker) === -1) { //no ticker
      return false;
    } else {
      //console.log(tmpAccountABP.abpList);
      return tmpAccountABP.abpList[ticker]; //return final data obj
    }
  }
}

export const setAccountTickerABP = (slotId, ticker, data) => {
  ticker = String(ticker).toLowerCase();
  let tmpAccountABP = JSON.parse(localStorage.getItem("accabp" + String(slotId)));

  console.log("SET");
  console.log(ticker);
  console.log(data);

  if (tmpAccountABP === null) {
    tmpAccountABP = {slotId: slotId, abpList: {}};
    tmpAccountABP.abpList[ticker] = {abp: 0, amt: 0, timestamp: 1600000000000000};
  } else {
    tmpAccountABP.abpList[ticker] = data;
  }
  localStorage.setItem("accabp" + String(slotId), JSON.stringify(tmpAccountABP));
  return JSON.parse(localStorage.getItem("accabp" + String(slotId)));
}

export const setAccountTickerABPElement = (slotId, ticker, type, data) => {
  ticker = String(ticker).toLowerCase();
  let tmpAccountABP = JSON.parse(localStorage.getItem("accabp" + String(slotId)));
  console.log(tmpAccountABP);

  if (tmpAccountABP === null) {
    tmpAccountABP = {slotId: slotId, abpList: {}};
    tmpAccountABP.abpList[ticker] = {abp: 0, amt: 0, timestamp: 1600000000000000};
  } else if (tmpAccountABP.abpList[ticker] === undefined) {
    tmpAccountABP.abpList[ticker] = {abp: 0, amt: 0, timestamp: 1600000000000000};
  } else if (type === "ABP") {
    tmpAccountABP.abpList[ticker].abp = Number(data);
  } else if (type === "AMT") {
    tmpAccountABP.abpList[ticker].amt = Number(data);
  } else if (type === "TIMESTAMP") {
    tmpAccountABP.abpList[ticker].timestamp = Number(data);
  }

  console.log(tmpAccountABP.abpList);

  localStorage.setItem("accabp" + String(slotId), JSON.stringify(tmpAccountABP));
  return JSON.parse(localStorage.getItem("accabp" + String(slotId)));
}


/* Price Feed Area */
/*
pf data structure
localstorage id : accpf0 ~ 9
localstorage data : {slotId: 0, pfList: ["ETHKRW", "BTCKRW", ...]}
*/

export const getAccountPF = (slotId) => {
  let tmpAccountABP = JSON.parse(localStorage.getItem("accabp" + String(slotId)));
  console.log(tmpAccountABP);
  console.log(slotId);

  if (tmpAccountABP === null) {
    return false;
  } else {
    return(tmpAccountABP);
  }
}