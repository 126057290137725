//should update when bithumb lists new tokens

export const tickers = [
  "krw",
  "btc",
  "eth",
  "dash",
  "ltc",
  "etc",
  "xrp",
  "bch",
  "xmr",
  "zec",
  "qtum",
  "btg",
  "eos",
  "icx",
  "ven",
  "trx",
  "elf",
  "mith",
  "mco",
  "omg",
  "knc",
  "glm",
  "hsr",
  "zil",
  "ethos",
  "pay",
  "waxp",
  "powr",
  "lrc",
  "gto",
  "pch",
  "eosdac",
  "steem",
  "strax",
  "ae",
  "zrx",
  "rep",
  "xem",
  "snt",
  "ada",
  "ppt",
  "ctxc",
  "bat",
  "wtc",
  "cmt",
  "theta",
  "poly",
  "loom",
  "cvc",
  "man",
  "waves",
  "itc",
  "true",
  "abt",
  "link",
  "meetone",
  "horus",
  "add",
  "salt",
  "pst",
  "bhpc",
  "rnt",
  "enj",
  "plx",
  "vet",
  "mtl",
  "rdn",
  "ins",
  "chl",
  "black",
  "atd",
  "iost",
  "ocn",
  "tmtg",
  "qkc",
  "bznt",
  "arn",
  "hdac",
  "npxs",
  "lba",
  "wet",
  "amo",
  "bsv",
  "keos",
  "rom",
  "bxa",
  "apis",
  "dac",
  "dacc",
  "auto",
  "orbs",
  "tfuel",
  "valor",
  "con",
  "ankr",
  "mix",
  "vtho",
  "lamb",
  "cro",
  "fx",
  "chr",
  "mbl",
  "mxc",
  "fab",
  "ogo",
  "win",
  "dvp",
  "fct",
  "fnb",
  "fzz",
  "trv",
  "pcm",
  "dad",
  "aoa",
  "xsr",
  "wom",
  "soc",
  "em",
  "qbz",
  "boa",
  "wpx",
  "fleta",
  "bnp",
  "sxp",
  "cos",
  "apix",
  "el",
  "basic",
  "hive",
  "xpr",
  "vra",
  "fit",
  "egg",
  "bora",
  "arpa",
  "apm",
  "ckb",
  "aergo",
  "anw",
  "cennz",
  "evz",
  "cyclub",
  "srm",
  "qtcon",
  "uni",
  "yfi",
  "uma",
  "aave",
  "comp",
  "ren",
  "bal",
  "rsr",
  "nmr",
  "rlc",
  "uos",
  "sand",
  "cvt",
  "stpt",
  "gom2",
  "ringx",
  "bel",
  "dvc",
  "obsr",
  "orc",
  "pola",
  "awo",
  "adp",
  "dvi",
  "drm",
  "ibp",
  "ghx",
  "mir",
  "cbk",
  "onx",
  "mvc",
  "bly",
  "wozx",
  "anv",
  "grt",
  "mm",
  "biot",
  "xno",
  "snx",
  "rai",
  "cola",
  "nu",
  "oxt",
  "lina",
  "asta",
  "map",
  "aqt",
  "wiken",
  "ctsi",
  "mana",
  "lpt",
  "mkr",
  "srt",
  "sushi",
  "nsbt",
  "don",
  "asm",
  "pundix",
  "celr",
  "lf",
  "arw",
  "beth",
  "bcdc",
  "msb",
  "rly",
  "ocean",
  "bfc",
  "alice",
  "cake",
  "bnt",
  "xvs",
  "chz",
  "axs",
  "dai",
  "matic",
  "bake",
  "velo",
  "anc",
  "hc",
  "bcd",
  "xvg",
  "xlm",
  "pivx",
  "etz",
  "gxc",
  "bhp",
  "btt",
  "hyc",
  "vsys",
  "ipx",
  "wicc",
  "ont",
  "luna",
  "news",
  "aion",
  "meta",
  "klay",
  "cosm",
  "ong",
  "algo",
  "jst",
  "xtz",
  "itg",
  "evt369l",
  "mlk",
  "wemix",
  "dot",
  "sun_old",
  "atom",
  "ssx",
  "bcha",
  "temco",
  "lzm",
  "hibs",
  "cpay",
  "qi",
  "evt12b1",
  "burger",
  "doge",
  "ksm",
  "ctk",
  "xym",
  "bnb",
  "nft",
  "sun",
  "xec",
  "pci",
  "sol",
  "ln"
]