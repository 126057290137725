import React from 'react';
import Dropdown from 'react-dropdown';
//import 'react-dropdown/style.css';

import './ts-dropdown.css';
import './react-dropdown-custom-style.css';


function TSDropdown (props) {
  const options = props.options;
  const defaultOption = (props.value === null ? options[0] : props.value);
  const placeholder = (props.placeholder === null ? "" : props.placeholder);

	return (
    <div className='ts-dropdown'>
      <Dropdown options={options} value={defaultOption} placeholder={placeholder} onChange={props.selectHandler} disabled={props.disabled}/>
    </div>
  )
}

TSDropdown.defaultProps = {
  options: ["N/A"],
  value: null,       //not necessary
  placeholder: null, //not necessary
  disabled: false,
  selectHandler: () => {}
}

export default TSDropdown;