import React, { Component } from 'react';
import moment from 'moment';

import * as LSM from "../../../managers/localstorageManager/localstorageManager";
import * as BAM from "../../../managers/bithumbApiManager/bithumbApiManager";

import HistoryContentList from '../../UI/organisms/ts-history-content-list/ts-history-content-list';
import HistoryContentLog from  '../../UI/organisms/ts-history-content-log/ts-history-content-log';
import HistoryControlCall from '../../UI/organisms/ts-history-control-call/ts-history-control-call';

import './ts-page-history.css';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

class TSHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mainAccount: {},
      logs: "",
      selectedPage: 0,
      selectedPairs: [],
      selectedPairsABP: {abp: 0, amt:0, timestamp: 10000000},
      tradehistoryList: [],
    }
  }

  componentDidMount = () => {
    let lsmMain = LSM.getMainAccount();

    this.setState({
      mainAccount: lsmMain
    })
  }

  componentWillUnmount = () => {

  }


  loadLocalstorageABPData = () => {
    //console.log(LSM.getAccountABP(0));
    //console.log(LSM.getAccountTickerABP(0, "XRP"));
    //console.log(LSM.getAccountTickerABP(this.state.mainAccount.slotId, this.state.selectedPairs[0]));
    
    let result = LSM.getAccountTickerABP(this.state.mainAccount.slotId, this.state.selectedPairs[0]);

    this.setState({
      selectedPairsABP: result
    })
    
    console.log(this.state);
  }
  
  editLocalstorageABPData = (data) => {
    if(this.state.mainAccount.slotId === undefined) return false;
    if(this.state.selectedPairs[0] === undefined) return false;

    LSM.setAccountTickerABP(this.state.mainAccount.slotId, this.state.selectedPairs[0], data);
  }
  editLocalstorageABPDataElement = (elementName, data) => {
    console.log(elementName);
    console.log(data);

    if(this.state.mainAccount.slotId === undefined) return false;
    if(this.state.selectedPairs[0] === undefined) return false;

    LSM.setAccountTickerABPElement(this.state.mainAccount.slotId, this.state.selectedPairs[0], elementName, data);
  }

  addLog = (log) => {
    this.setState({
      logs: "[" + String(moment().format()) + "] " + JSON.stringify(log) + "\n" + this.state.logs
    });
  }

  userActionHandler = async (action, data) => {
    if(action === "LOGCLEAR") {
      this.setState({
        logs: ""
      });
    } else if(action === "PAIR") { 
      await this.setState({
        selectedPage: 0,
        selectedPairs: data,
      })
      this.addLog("new pair select : " + JSON.stringify(data));
      this.loadTradeHistory();
    } else if (action === "ABPCALC") {
      this.abpCalculate();
    } else if (action === "EDIT") { 
      console.log("EDIT")
      console.log(data);
      this.editLocalstorageABPDataElement(data.target, data.input);
    } else if (action === "PAGE") { 
      console.log("PAGE")

      await this.setState({
        selectedPage: (this.state.selectedPage + data) < 0 ? 0 : this.state.selectedPage + data
      })

      this.loadTradeHistory();
    } else {
      return false;
    }

    this.loadLocalstorageABPData();
  }

  logClearHandler = () => {
    this.setState({
      logs: ""
    });
  }

  submitHandler = async (data) => {
    await this.setState({
      selectedPairs: data
    })
    this.addLog("new pair select : " + JSON.stringify(data));
    this.loadTradeHistory();
    this.loadLocalstorageABPData();
  }

  loadTradeHistory = async () => {
    this.addLog("load pair : " + JSON.stringify(this.state.selectedPairs));

    let hobj = {
      "offset": (this.state.selectedPage * 50), //0,
      "count": 50,
      "mode": 0,
      "orderCurrency": this.state.selectedPairs[0],
      "paymentCurrency": this.state.selectedPairs[1],
    }

    console.log(hobj);

    let result = await BAM.getTradeHistory(this.state.mainAccount.slotId, hobj, this.state.mainAccount.con, this.state.mainAccount.sec);
    console.log(result);

    if(result !== false) {
      this.setState({
        tradehistoryList: result.data
      })
    }
  }

  loadTradeHistoryForCalculation = async (page) => {
    let hobj = {
      "offset": (page * 50), //0,
      "count": 50,
      "mode": 0,
      "orderCurrency": this.state.selectedPairs[0],
      "paymentCurrency": this.state.selectedPairs[1],
    }

    return (await BAM.getTradeHistory(this.state.mainAccount.slotId, hobj, this.state.mainAccount.con, this.state.mainAccount.sec)).data;
  }

  abpCalculate = async () => {
    console.log("ABPCALC");

    //초기 상태 정의, 마지막으로 평단가를 조회한 시간과 그 때의 값 확인
    /*
    { //xrp 소액 50회 매매 이전 데이터
      abp: 501.70010144087337,
      amt: 1142.9318,
      timestamp: 1659379254000000
    }
    */
    let tmpCondition = {}
    /*
    {
      abp: 501.70010144087337,
      amt: 1142.9318,
      timestamp: 1659379254000000
    }*/
    tmpCondition = this.state.selectedPairsABP; //all number
    //console.log(tmpCondition); 

    let calcStack = [];
    let loadResult = null;
    let shoudContinue = true;

    loadResult = await this.loadTradeHistoryForCalculation(0);

    if(loadResult.length === 0) {
      console.log("NO DATA");
      return false;
    }

    for(let i = 0; shoudContinue; i++) {
      loadResult = await this.loadTradeHistoryForCalculation(i);
      
      console.log(loadResult.length);

      if(loadResult.length === 0) {
        this.addLog("NO MORE TRADES, BUT NOT FINAL DATA - BREAKING ALL.");
        return false;
      }

      for(let j = 0; j < loadResult.length; j++) {
        //console.log(loadResult);
        console.log(loadResult[j].transfer_date);
        console.log(Number(tmpCondition.timestamp))

        if(Number(loadResult[j].transfer_date) > Number(tmpCondition.timestamp)) { //new trades only
          if(loadResult[j].search === '1' || loadResult[j].search === '2') { //buy or sell
            this.addLog("PUSHED NEW TRADE");
            calcStack.push(loadResult[j]);  
          }
        } else {
          this.addLog("LOADED LATEST TRADE SUCCESSFULLY");
          shoudContinue = false;
          break;
        }
      }

      this.addLog("Page " + String(i) + " Scanned. Looking for next page");

      if(i>100) {
        shoudContinue = false;
        return false;
        //failsafe
      }
    }

    console.log(calcStack);
    this.addLog("ABP Stack Load Success");
    this.addLog("LOADED ALL NEW TRADE SUCCESSFULLY");
    //마지막 업데이트 이후로 모든 거래 데이터들을 성공적으로 로드 함

    /*
    to-do : 스택에 집어넣으면서 timestamp 비교, timestamp 초과값이 나올 때 까지 계속 집어넣기
    페이지가 안 나오면 다음 페이지를 로딩하고, state는 하나의 페이지만 가지도록 처리해야 할 듯.
    즉 변수로만 모든 페이지 데이터를 쌓아두고, 페이지는 조회/페이지 이동 할 때마다 계속 재조회
    
    스택 계속 집어넣다가 타임스탬프 실패했는데 다음 데이터가 없으면 Exit,
    스택 집어넣다가 타임스탬프 이전값이 뜨면 stop 하고 스택 인출하면서 기존 연산식 사용

    loadforcalculation -> stack&checkTime -> load ....
    */



    //data.order_balance : 거래 체결 이후 남은 토큰 수량
    //data.units: 거래 체결 수량
    //data.amount: 거래 금액 KRW

    let initLength = calcStack.length;
    for(let i = 0; i < initLength; i++) {  //거래내역 스택 전체를 순회, 단, pop으로 과거 - 현재 순 선입선출 진행함.
      let tradeData = calcStack.pop();

      if(tradeData.search === '1') { //매수 거래내역 처리
        console.log(i);
        console.log("PROCESS BUY");
        console.log(tradeData);

        //price, units, amount, transfer_date

        let tmpTotalBoughtKRW = (tmpCondition.abp * tmpCondition.amt) + Number(tradeData.amount); //data.amount is KRW, condition.amount is Token
        let tmpTotalBoughtCoin = tmpCondition.amt + Number(tradeData.units);
        let tmpTotalBoughtABP = Number((tmpTotalBoughtKRW / tmpTotalBoughtCoin));

        //이전 평단가와 매수 수량을 활용해, 새 매수 주문을 통해 늘어난 총 매수 수량과 총 매수 KRW를 새로 계산
        //이후, 총 매수 KRW/총 매수 수량으로 평단가를 기록

        tmpCondition = {
          abp: tmpTotalBoughtABP,
          amt: tmpTotalBoughtCoin,
          timestamp: Number(tradeData.transfer_date)
        }
      }
      else if(tradeData.search === '2') { //sell
        console.log(i);
        console.log("PROCESS SELL");
        console.log(tradeData);

        //매도 시에는 보유 수량만 줄어드는 것으로 처리
        tmpCondition = {
          abp: tmpCondition.abp, //평단가 그대로
          amt: (tmpCondition.amt - Number(tradeData.units)) < 0.00001 ? 0 : tmpCondition.amt - Number(tradeData.units), //near-zero cutoff
          timestamp: Number(tradeData.transfer_date)
        }
      }

      console.log(tmpCondition);

      //소숫점 아래 오차 보정하고, 0 가까이 갔을때 처리하는 등의 방법을 핸들링 해야 함.
    }

    console.log("FINAL ABP")
    console.log(tmpCondition);

    this.editLocalstorageABPData(tmpCondition);
    /*
    this.editLocalstorageABPData({abp: 494.90196046600477,
      amt: 0.6101426399999994,
      timestamp: 1659511537012507})
    */
    this.loadLocalstorageABPData();
  }

  render = () => {
    return (
      <div className="ts-page-history-container">
        <div className="ts-page-history-content">
          <div className='ts-page-history-content-1'>
            <HistoryContentList tradehistoryList={this.state.tradehistoryList} tradehistoryPage={this.state.selectedPage}/>
          </div>
          <div className="ts-page-history-content-gap"/>
          <div className='ts-page-history-content-2'>
            <HistoryContentLog logData={this.state.logs} logClear={()=>{this.userActionHandler("LOGCLEAR")}}/>
          </div>
        </div>
        <div className="ts-page-history-control">
          <HistoryControlCall submitHandler={(action, data) => {this.userActionHandler(action, data)}} recalcHandler={() => {this.userActionHandler("ABPCALC")}} selectedPairs={this.state.selectedPairs} abpData={this.state.selectedPairsABP}/>
        </div>
      </div>
    ) 
  }
}

export default TSHistory;