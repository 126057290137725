import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSAccountEditor from '../../molecules/ts-account-editor/ts-account-editor';

import "./ts-account-control-edit.css";

class AccountControlEdit extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TSModule
        label="Edit Account"
        content={<TSAccountEditor selectedSlotId={this.props.selectedSlotId} mainAccountId={this.props.mainAccountId} editAccountHandler={this.props.editAccountHandler}/>}
      />
    )
  }
}

AccountControlEdit.defaultProps = {
  selectedSlotId: 0,
  mainAccountId: 0,
  editAccountHandler: () => {}
}

export default AccountControlEdit;