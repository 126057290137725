import React from 'react';
import './ts-table-history.css';

function renderType (type) {
  //(0 : 전체, 1 : 매수 완료, 2 : 매도 완료, 3 : 출금 중 4 : 입금, 5 : 출금, 9 : KRW 입금 중)

  switch(String(type)){
    case '1':
      return <p className='ts-table-history-highlight-buy'>BUY</p>
    case '2':
      return <p className='ts-table-history-highlight-sell'>SELL</p>
    case '3':
      return <p className='ts-table-history-highlight-etc'>WDNG</p>
    case '4':
      return <p className='ts-table-history-highlight-etc'>DPST</p>
    case '5':
      return <p className='ts-table-history-highlight-etc'>WDRW</p>
    case '9':
      return <p className='ts-table-history-highlight-etc'>KDPS</p>
    default: 
      return <p className='ts-table-history-highlight-etc'>N/A</p>
  }
}

function renderNumber (num, digit) {
  let tmpDigit = 8;
  if(digit == null) tmpDigit = 8;
  else tmpDigit = digit;
  return Number(num).toLocaleString('en-US', {minimumFractionDigits: tmpDigit, maximumFractionDigits: tmpDigit});
}

function renderDate (timestamp) {
  const D = new Date((Number(timestamp)/1000));
  const result = D.toTimeString('ko-KR').substring(0, 8) + "(" + D.toISOString().substring(2, 10) + ")";

  return result;
}


function TSTableHistory (props) {  
  let tmpList = [];

  for(let i = 0; i < props.tradehistoryList.length; i++) {
    //normal, normal-updated, odd, odd-updated

    let trClassName = ""; //even and not updated
    //if(i % 2 === 0 && props.tradehistoryList[i].isUpdated)       trClassName="ts-table-history-tr-updated";     //even and updated
    //else if(i % 2 !== 0 && props.tradehistoryList[i].isUpdated)  trClassName="ts-table-history-tr-odd-updated"; //odd and updated

    if(i % 2 !== 0 && !props.tradehistoryList[i].isUpdated) trClassName="ts-table-history-tr-odd";         //odd and not updated

    tmpList.push(
      <tr className={trClassName} key={String(props.tradehistoryList[i].transfer_date) + String(props.tradehistoryList[i].search)}>
        <td className='ts-table-history-r1 ts-table-history-text ts-table-history-text-td'>{String(props.tradehistoryList[i].order_currency + props.tradehistoryList[i].payment_currency).toUpperCase()}</td>
        <td className='ts-table-history-r2'>{renderType(props.tradehistoryList[i].search)}</td>
        <td className='ts-table-history-r3'>{renderNumber(props.tradehistoryList[i].units.replace(/ /g,""), 4)}</td>
        <td className='ts-table-history-r4'>{renderNumber(props.tradehistoryList[i].price, 2)} </td>
        <td className='ts-table-history-r5'>{renderNumber(props.tradehistoryList[i].amount, 0)}</td>
        <th className='ts-table-history-rn'/>
        <td className='ts-table-history-r6'>{renderDate(props.tradehistoryList[i].transfer_date)}</td>
      </tr>
    )
  }
  
	return (
    <div className='ts-table-history-wrapper'>
      <table className='ts-table-history'>
          <thead>
            <tr className='ts-table-history-tr-odd ts-table-history-text' key="head">
              <th className='ts-table-history-r1'>Pair  </th>
              <th className='ts-table-history-r2'>Type  </th>
              <th className='ts-table-history-r3'>Amount</th>
              <th className='ts-table-history-r4'>Price </th>
              <th className='ts-table-history-r5'>Total </th>
              <th className='ts-table-history-rn'/>
              <th className='ts-table-history-r6'>Time (Date)</th>
            </tr>
          </thead>
          <tbody>
            {tmpList}
          </tbody>
        </table>
    </div>
  )
}

//{"search":"1","transfer_date":"1656318927103438","order_currency":"XRP","payment_currency":"KRW","units":"2114.62518644","price":"469.3","amount":"992394","fee_currency":"KRW","fee":"0","order_balance":"2121.92153519","payment_balance":"2484"}

TSTableHistory.defaultProps = {
  /*
  tradehistoryList: [{
    isUpdated: false,
    ticker: "XRPKRW",
    balance: 0,
    avail: 0,
    worth: 0,
    price: 0,
    abp: 0
  }]
  */
  tradehistoryList: [
//    {"search":"BUY","transfer_date":"1656318927102318","order_currency":"XRP","payment_currency":"KRW","units":"152.35","price":"469.2","amount":"938","fee_currency":"KRW","fee":"0","order_balance":"7.29634875","payment_balance":"994878"},
  ]
}

export default TSTableHistory;