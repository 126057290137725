import React from 'react';
import './ts-btn-simple.css';

function TSButton (props) {
	return (
    <button className={"ts-btn-simple " + "ts-btn-simple-" + props.theme + (props.adaptive ? " ts-btn-simple-adaptive" : "")} onClick={() => {props.clickHandler()}}>
      {props.label}
    </button>
  )
}

TSButton.defaultProps = {
  theme: "dark", //dark, blue, transparent-blue
  label: "",
  adaptive: false,
  clickHandler: () => {}
}

export default TSButton;