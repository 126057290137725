import React, { Component } from 'react';

import * as LSM from "../../../managers/localstorageManager/localstorageManager";
import * as BAM from "../../../managers/bithumbApiManager/bithumbApiManager";
import * as BDAM from "../../../managers/bithumbDirectApiManager/bithumbDirectApiManager";

import FeedControlCall from "../../UI/organisms/ts-feed-control-call/ts-feed-control-call";
import FeedContentList from '../../UI/organisms/ts-feed-content-list/ts-feed-content-list';

import './ts-page-feed.css';

class TSFeed extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tickerData: [],
      statusData: [],
      tickerUpdateInterval: null,
      userSelection: ["BTC", "ETH", "ETC", "XRP", "ADA", "EOS", "SOL", "ATOM", "KLAY"],
    }
  }

  /*
  statusData : array of following
  {
    timestamp: ""     //readable timestamp
    timestampUNIXMS: "" //unix milisec timestamp
    isOnline: false,  //true if data is loaded
    isUpdated: [false, false, false, false, false, false, false] //true if data is updated in comparison to the last one and should highlighted. -> remove dat
    updateDetail: ["UP", "DOWN", "", ...]                        //detail of update that can be used for indication.
  }
  */

  //to-do
  /*
  Load data from bithumb, using frontend-call or server-side call (if server, use api stack)
  Load data from Localstorage to filter which pair to show
  Editor Molecule will directly set Localstorage
  LOAD all price using api call, and filter what to show using loaded filter
  */

  componentDidMount = () => {
    //start setinterval to use BAM.localGetAllPrice 
    //add function that calls BAM.localGetAllPrice and set it into the state - setState so that it gets updated Automatically.
    setTimeout(this.startAutoTickerUpdate, 1000);
  }

  componentWillUnmount = () => {
    //clear in-state interval to stop BAM.localGetAllPrice
    this.stopAutoTickerUpdate();
  }

  diffRecorder = (oldData, newData) => {
    const compareSheet = ["closing_price", "opening_price", "max_price", "min_price", "acc_trade_value", "units_traded", "fluctate_24H"]
    let resultSheet = [];
    //console.log(oldData);
    //console.log(newData);

    try {
      for(let i = 0; i < compareSheet.length; i++) {
        if(oldData[compareSheet[i]] === newData[compareSheet[i]]) {
          resultSheet.push("");
        } else if (Number(oldData[compareSheet[i]]) < Number(newData[compareSheet[i]])) {
          resultSheet.push("up");
        } else if (Number(oldData[compareSheet[i]]) > Number(newData[compareSheet[i]])) {
          resultSheet.push("dn")
        } else {
          console.log("ERROR");
          resultSheet.push("");
        }
      }
      resultSheet.push(""); //push no-update for timestamp

    } catch(err) {
      console.log("COMPARE ERRROR");
      return ["", "", "", "", "", "", "", ""];
    }

    return resultSheet;
  }

  loadAllTicker = async () => {
    let result = await BDAM.getTickerDirect("ALL");

    //handler area

    let filteredResult = [];
    let statusResult = [];
    let statusResultClear = [];

    //console.log(result);

    try {
      if(Number(this.state.statusData[0].timestampUNIXMS) > Number(result.timeUNIXMS)) {
        console.log("OLD DATA RECEIVED. Skipping data fetch...");
        //console.log(this.state.statusData);
        return; //skip updating if old data is received
      }
    } catch(err) {
      //initial load compare error. should fix later
    }

    if (result === false) { //handle api call error
      for (let i = 0; i < this.state.userSelection.length; i++) {
        statusResult.push({ timestamp: "", timestampUNIXMS: "", isOnline: false, updateDetail: ["", "", "", "", "", "", "", ""] });
      }
      await this.setState({ //doesn't update the ticker data and shut the ticker card down
        statusData: statusResult
      })
    } else { //on successful result
      for (let i = 0; i < this.state.userSelection.length; i++) {
        filteredResult.push(result.data[this.state.userSelection[i]])

        statusResult.push({ timestamp: result.timeCustom, timestampUNIXMS: result.timeUNIXMS, isOnline: true, updateDetail: (this.diffRecorder(this.state.tickerData[i], result.data[this.state.userSelection[i]])) });
        statusResultClear.push({ timestamp: result.timeCustom, timestampUNIXMS: result.timeUNIXMS, isOnline: true, updateDetail: ["", "", "", "", "", "", "", ""] })
      }

      await this.setState({
        tickerData: filteredResult,
        statusData: statusResult
      })

      setTimeout(() => {this.setState({ statusData: statusResultClear })}, 375)
    }
  }

  startAutoTickerUpdate = async () => {
    await this.stopAutoTickerUpdate();

    await this.setState({
      tickerUpdateInterval: setInterval(this.loadAllTicker, 750)
    })
  }

  stopAutoTickerUpdate = async () => {
    await clearInterval(this.state.tickerUpdateInterval);

    await this.setState({
      tickerUpdateInterval: null
    })
  }

  render = () => {
    //toss all price list and filter option to childs.
    return (
      <div className="ts-page-feed-container">
        <div className="ts-page-feed-content">
          <FeedContentList tickers={this.state.userSelection} tickersData={this.state.tickerData} statusData={this.state.statusData} />
        </div>
        <div className="ts-page-feed-control">
          <FeedControlCall />
          <button onClick={() => { this.loadAllTicker() }}>One-time Test</button>
          <button onClick={() => { this.startAutoTickerUpdate() }}>START Feed</button>
          <button onClick={() => { this.stopAutoTickerUpdate() }}>STOP Feed</button>
        </div>
      </div>
    )
  }
}

export default TSFeed;