import React, { Component } from 'react';

import TSInput from '../../atoms/ts-input/ts-input';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';

import './ts-account-editor.css';

class TSAccountEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputNick: "",
      inputCon: "",
      inputSec: "",
    }
  }

  inputHandler = (e) => {
    if(e.target.name === "NICK") {
      this.setState({
        inputNick: e.target.value
      })
    }
    if(e.target.name === "CON") {
      this.setState({
        inputCon: e.target.value
      })
    }
    if(e.target.name === "SEC") {
      this.setState({
        inputSec: e.target.value
      })
    }
  }

  render() {
    /*
      //can use this style when needed to edit the input data
      //currently using passive-style (active:false) to just read that one

      <div className='ts-account-editor-inputarea'>
        <TSInput label="Account Nickname" name="NICK" isActive={true} value={this.state.inputNick} inputHandler={this.inputHandler}/>
        <TSInput label="Connect Token"    name="CON"  isActive={true} value={this.state.inputCon}  inputHandler={this.inputHandler}/>
        <TSInput label="Secret Token"     name="SEC"  isActive={true} value={this.state.inputSec}  inputHandler={this.inputHandler}/>
      </div>

    */
	  return (
      <div className="ts-account-editor">
        <div className='ts-account-editor-indicator'>
          <p>SLOT  <span>0{this.props.selectedSlotId}</span></p>
          <p>Main  <span>0{this.props.mainAccountId} </span></p>
        </div>

        <div className='ts-account-editor-inputarea'>
          <TSInput label="Account Nickname" name="NICK" isActive={false} inputHandler={this.inputHandler}/>
          <TSInput label="Connect Token"    name="CON"  isActive={false} inputHandler={this.inputHandler}/>
          <TSInput label="Secret Token"     name="SEC"  isActive={false} inputHandler={this.inputHandler}/>
        </div>

        <div className='ts-account-editor-buttonarea'>
          <div className='ts-account-editor-buttonarea-1'>
            <TSButton theme="dark" label="Clear" clickHandler={()=>{this.props.editAccountHandler(this.props.selectedSlotId, "CLEAR")}}/>
          </div>
          <div className='ts-account-editor-buttonarea-2'>
            <TSButton theme="blue" label="Save" clickHandler={()=>{this.props.editAccountHandler(this.props.selectedSlotId, "SAVE", this.state)}}/>
          </div>
        </div>
      </div>
    )
  }
}

TSAccountEditor.defaultProps = {
  selectedSlotId: 0,
  mainAccountId: 0,
  editAccountHandler: () => {}
}

export default TSAccountEditor;