import React from 'react';
import './ts-module.css';

function TSModule (props) {

  // Props
  // label : string - ('light' : white background/blue text, 'dark' : dark background/white text)
  // content : react dom - content displayed

  if(props.noLabel) { //no label area
    return (
      <div className='ts-module'>
        <div className='ts-module-content'>
          {props.content}
        </div>
      </div>
    )
  } else {
    return (
      <div className='ts-module'>
        <div className='ts-module-label'>
          <h2>{props.label}</h2>
        </div>
        <div className='ts-module-content'>
          {props.content}
        </div>
      </div>
    )
  }
}

TSModule.defaultProps = {
  noLabel: false,
  label: "Label",
  content: null
}

export default TSModule;