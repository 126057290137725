import Axios from 'axios';
import moment from 'moment';

import * as CL from '../../const/currency';

const BT_ENDPOINT = "https://api.bithumb.com/"

export const getTickerDirect = async (ticker) => {
  let tickerReq = ticker + "_KRW"
  let result;
  
  try {
    result = await Axios.get(BT_ENDPOINT + "public/ticker/" + tickerReq);
  } catch(err) {
    return false;
  }

  //console.log(result.data);

  if(result.data.status === "0000") {
    return ({
      data:       result.data.data,
      time:       moment(Number(result.data.data.date)).format(),
      timeCustom: moment(Number(result.data.data.date)).format("YYYY-MM-DD HH:mm:ss.SS"),
      timeUNIXMS: result.data.data.date, //unix ms
    });
  } else {
    return false;
  }
}