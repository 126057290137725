import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import * as LSM from "../../../../managers/localstorageManager/localstorageManager";

import TSSidemenuLogo from "../../molecules/ts-sidemenu-logo/ts-sidemenu-logo";
import TSSidemenuAccount from '../../molecules/ts-sidemenu-account/ts-sidemenu-account';
import TSSidemenuNav from "../../molecules/ts-sidemenu-nav/ts-sidemenu-nav";

import "./ts-sidemenu.css";

class Sidemenu extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let selected = 0;

    const sidemenuNavData = [
      {path: "/ts3/select",       label: "Account Select",  id: 0},
      {path: "/ts3/detail",       label: "Accounts Detail", id: 1},
      {path: "/ts3/history",      label: "Trade History",   id: 2},
      {path: "/ts3/automator",    label: "Automator",       id: 3},
      {path: "/ts3/feed",         label: "Price Feed",      id: 4},
    ]

    switch(this.props.location.pathname) {
      case '/':
        selected = -1;
        break;
      case '/ts3/select':
        selected = 0;
        break;
      case '/ts3/detail':
        selected = 1;
        break;
      case '/ts3/history':
        selected = 2;
        break;
      case '/ts3/automator':
        selected = 3;
        break;
      case '/ts3/feed':
        selected = 4;
        break;
      default:
        break;
    }

    return (
      <div className="ts-sidemenu">
        <TSSidemenuLogo/>
        <TSSidemenuAccount mainAccount={LSM.getMainAccount().nick}/>
        <TSSidemenuNav navData={sidemenuNavData} selectedMenu={selected}/>
      </div>
    )
  }
}

export default withRouter(Sidemenu);