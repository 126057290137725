import React, { Component } from 'react';

import * as LSM from "../../../managers/localstorageManager/localstorageManager"

import AccountContentList from '../../UI/organisms/ts-account-content-list/ts-account-content-list';
import AccountControlEdit from '../../UI/organisms/ts-account-control-edit/ts-account-control-edit';

import './ts-page-select.css';


class TSSelect extends Component {

  //top-level data starts from here - including states/callbacks/etc.

  constructor(props) {
    super(props);

    let tmpLDC = [];

    for(let i = 0; i < 10; i++) {
      tmpLDC.push({ slotId: i, nick: "No Account", con: "none", sec: "none" })
      //final spec - ({ slotId: i, nick: "No Account", con: "none", sec: "none", isSelected: false , isMain: false })
    }

    this.state = {
      selectedSlotId: 0,
      mainAccountId: 0,
      accountListData: tmpLDC,
    }
  }

  reloadLocalAccountInfo = () => {
    this.setState({
      mainAccountId: LSM.getMainAccountNumber(),
      accountListData: LSM.getAccounts()
    })
  }

  selectAccountHandler = (id) => {
    this.setState({
      selectedSlotId: id
    });
  }

  selectMainHandler = (id) => {
    //should edit localstorage too
    LSM.setMainAccountNumber(id);
    this.reloadLocalAccountInfo();
  }

  editAccountHandler = (id, op, data) => {
    if (op === "CLEAR") {
      LSM.setAccount(id, {
        slotId: id,
        con: "00000000000000000000000000000000",
        sec: "00000000000000000000000000000000",
        nick: "NO ACCOUNT"
      })
      //to-do : remove abp data
    }
    else if (op === "SAVE") {
      LSM.setAccount(id, {
        slotId: id,
        con: data.inputCon,
        sec: data.inputSec,
        nick: data.inputNick
      })
    }

    this.reloadLocalAccountInfo();
  }

  componentDidMount = () => {
    this.reloadLocalAccountInfo();
  }

  render = () => {
    // each content/control area is just div with width/height layout
    // should add some organisms into the area
    // templates : will skip for now. check again later

    let accountDataFinal = [];
    for(let i = 0; i < this.state.accountListData.length; i++) {
      accountDataFinal.push({ ...this.state.accountListData[i], isSelected: (i === this.state.selectedSlotId), isMain: (i === this.state.mainAccountId) })
    }
    
    return (
      <div className="ts-page-select-container">
        <div className="ts-page-select-content">
          <AccountContentList accountListData={accountDataFinal} selectAccountHandler={this.selectAccountHandler} selectMainHandler={this.selectMainHandler}/>
        </div>
        <div className="ts-page-select-control">
          <AccountControlEdit selectedSlotId={this.state.selectedSlotId} mainAccountId={this.state.mainAccountId} editAccountHandler={this.editAccountHandler}/>
        </div>
      </div>
    ) 
  }
}

export default TSSelect;