import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSAccountListSelector from '../../molecules/ts-account-list-selector/ts-account-list-selector';

import "./ts-account-content-list.css";

class AccountContentList extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const finalContent = (
      <div className='ts-account-content-list'>
        <div className='ts-account-content-list-th'>
          <p className='ts-account-content-list-th-1'></p>
          <p className='ts-account-content-list-th-2'>SLOT NO</p>
          <p className='ts-account-content-list-th-3'>ACCOUNT NICK</p>
          <p className='ts-account-content-list-th-4'>CONNECT TOKEN</p>
          <p className='ts-account-content-list-th-5'>SECRET TOKEN</p>
          <p className='ts-account-content-list-th-6'>SET MAIN ACC</p>
        </div>
        <div>
          <TSAccountListSelector accountListData={this.props.accountListData} selectAccountHandler={this.props.selectAccountHandler} selectMainHandler={this.props.selectMainHandler}/>
        </div>
      </div>
    )

    //modulize and return
    return (
      <TSModule
        label="Account List & Select"
        content={finalContent}
      />
    )
  }
}

AccountContentList.defaultProps = {
  accountListData: [],
  selectAccountHandler: () => {},
  selectMainHandler: () => {},
}

export default AccountContentList;