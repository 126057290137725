import React from 'react';
import { Link, withRouter } from "react-router-dom";

import TSLogo from "../../atoms/ts-logo/ts-logo";

import './ts-sidemenu-logo.css';

function TSSidemenuLogo (props) {
	return (
    <div className="ts-sidemenu-logo">
      <Link to="/">
        <TSLogo/>
      </Link>
    </div>
  )
}

export default withRouter(TSSidemenuLogo);