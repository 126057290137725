import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';
import TSAccountDetailCard from '../../molecules/ts-accountdetail-card/ts-accountdetail-card';

import "./ts-accountdetail-content-list.css";

class AccountdetailContentList extends Component {

  constructor(props) {
    super(props);
  }
/*
  slotId: 0,
  nick: "NO ACCOUNT",
  timestamp: "2022-00-00 T09:00:00",
  isMain: false,
  assetList: [{}]
*/

  render = () => {
    let cardList = [];

    for(let i = 0; i < this.props.accountData.length; i++) {
      if(this.props.accountData[i].nick !== "NO ACCOUNT") { //filter no account
        cardList.push(
          <TSAccountDetailCard
            slotId={this.props.accountData[i].slotId}
            nick={this.props.accountData[i].nick}
            timestamp={this.props.accountData[i].timestamp}
            isMain={this.props.accountData[i].isMain}
            assetList={this.props.accountData[i].assetList}
            deltaList={this.props.accountData[i].deltaList}
            updater={this.props.updater}
          />
        )
      }
    }

    const finalContent = (
      <div className='ts-account-detail-content-list'>
        {cardList}
      </div>
    )

    //modulize and return
    return (
      <TSModule
        label="Account Detail"
        content={finalContent}
      />
    )
  }
}

AccountdetailContentList.defaultProps = {
  accountData: [],
  updater: () => {}
}

export default AccountdetailContentList;