import React, { Component } from 'react';

import Axios from 'axios';
import {CSVLink} from 'react-csv';

import './ts-page-tester.css';


class TSTester extends Component {

  //top-level data starts from here - including states/callbacks/etc.

  constructor(props) {
    super(props);

    this.state = {
      csvData: 'NONE'
    }
  }

  renderBinanceDate = (timestamp) => {
    if (timestamp === undefined) return null;

    const D = new Date((Number(timestamp)));
    const DDate = new Date((Number(timestamp) - (new Date().getTimezoneOffset() * 60000))); //timezone offset for kst

    const result = DDate.toISOString().substring(0, 10) + " " + D.toTimeString('ko-KR').substring(0, 8) //+ "(" + D.toISOString().substring(2, 10) + ")";

    return result;
  }
  renderExcelDate = (timestamp) => {
    if (timestamp === undefined) return null;

    const D = new Date((Number(timestamp)));
    const DDate = new Date((Number(timestamp) - (new Date().getTimezoneOffset() * 60000))); //timezone offset for kst

    const result = DDate.toISOString().substring(0, 10) + " " + D.toTimeString('ko-KR').substring(0, 8) //+ "(" + D.toISOString().substring(2, 10) + ")";

    console.log(D.toTimeString('ko-KR'));
    console.log(D.toTimeString('hh:mm'));

    return result;
  }

  binanceCall = async () => {
    const csvHeader = ["TIMESTAMP", "BTC", "UNI", "AAVE", "CRV", "COMP"];
    const tickers = ["BTC", "UNI", "AAVE", "CRV", "COMP"];
    let tickerResults = [[], [], [], [], []];
    let timestampResults = [];

    for(let i = 0; i < tickers.length; i++) {
      console.log(tickers[i]);
      let tmpResult = await Axios.get("https://api.binance.com/api/v3/klines?symbol=" + tickers[i] + "USDT&interval=1h&limit=1000"); //offset?
      for(let j = 0; j < tmpResult.data.length; j++) {
        if(i === 0) { //first-only-timestamp (careful when execution - 1h change timing !!!!!)
          timestampResults.push(this.renderBinanceDate(tmpResult.data[j][0]));
          //timestampResults.push(this.renderExcelDate(tmpResult.data[j][0]));
        }
        tickerResults[i].push(tmpResult.data[j][4]);
      }
    }

    //console.log(timestampResults);
    //console.log(tickerResults);

    let csvExportArray = [];
    
    for(let i = 0; i < timestampResults.length; i++) {
      let tmpRow = [];
      tmpRow.push(timestampResults[i]);

      for(let j = 0; j < tickerResults.length; j++) {
        tmpRow.push(tickerResults[j][i]);
      }

      csvExportArray.push(tmpRow);
    }

    //console.log(csvExportArray);

    csvExportArray.push(csvHeader);
    csvExportArray.reverse();

    this.setState({
      csvData: csvExportArray
    })
  }

  render = () => {
    // each content/control area is just div with width/height layout
    // should add some organisms into the area
    // templates : will skip for now. check again later

    return (
      <div className="ts-page-select-container">
        <button onClick={()=>{this.binanceCall()}}>J CALL</button>

        <CSVLink data={this.state.csvData}>Download FILE</CSVLink>
      </div>
    ) 
  }
}

export default TSTester;