import React, { Component } from 'react';

import TSModule from '../../atoms/ts-module/ts-module';

import "./ts-feed-control-call.css";

class FeedControlCall extends Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    const finalContent = (
      <div className='ts-feed-control-call'>
      
      </div>
    )

    //modulize and return

    return (
      <TSModule
        label="Add Pair"
        content={finalContent}
      />
    )
  }
}

FeedControlCall.defaultProps = {
  
}

export default FeedControlCall;