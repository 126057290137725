import React from 'react';
import HashLoader from "react-spinners/HashLoader";

import './ts-card-pricefeed.css';

function renderNumber (num, digit) {
  let tmpDigit = 8;
  if(digit == null) tmpDigit = 8;
  else tmpDigit = digit;
  return Number(num).toLocaleString('en-US', {minimumFractionDigits: tmpDigit, maximumFractionDigits: tmpDigit});
}

function renderPriceFeedRows (tag, data, detail, special) {
  let dataStyling = "";
  /*
    List of detail
    "up" : number increased. use red background
    "dn" : number decreased. use blue background
    "ch" : just change used for timestamp
  */

  /*
    List of special
    "bb" : bold for price or important data. use bold
    "pl" : plus percentage. use red text color
    "mn" : minus percentage. use blue text color
  */

  if(detail !== "") {
    dataStyling += (" pricefeed-" + detail);
  }

  if(special !== undefined) {
    dataStyling += (" pricefeed-" + special);
  }

  return(
    <div className='ts-card-pricefeed-content-row'>
      <p className='ts-card-pricefeed-content-tag'> {tag} </p>
      <p className={'ts-card-pricefeed-content-data' + dataStyling}>{data}</p>
    </div>
  )
}

function TSCardPricefeed (props) {
  if(!props.statusData.isOnline) {
    return (
      <div className='ts-card-pricefeed' id={JSON.stringify(props.headerData)}>
        <div className='ts-card-pricefeed-headline'>
          <p className='ts-card-pricefeed-headline-exchange'>{props.headerData.exchange}</p>
          <p className='ts-card-pricefeed-headline-ticker'>  {props.headerData.ticker}-{props.headerData.payment}</p>
        </div>
        <div className='ts-card-pricefeed-content'>
          <div className='ts-card-pricefeed-content-loading'>
            <HashLoader color="#929CCF"/>
          </div>
          <p className='ts-card-pricefeed-content-loading-text'>Loading Data...<br/>Click 'Start Feed' to start auto-load</p>
        </div>
      </div>
    )
  }

  const priceFeedTag = ["Current Price", "Open", "High", "Low", "Volume (KRW)", "Volume", "24H Change", "Timestamp"];
  let priceFeedContent = [];

  //console.log(props.statusData);

  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[0], renderNumber(props.tickerData.closing_price  , 2), props.statusData.updateDetail[0], "bb"));
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[1], renderNumber(props.tickerData.opening_price  , 2), props.statusData.updateDetail[1]));
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[2], renderNumber(props.tickerData.max_price      , 2), props.statusData.updateDetail[2]));
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[3], renderNumber(props.tickerData.min_price      , 2), props.statusData.updateDetail[3]));
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[4], renderNumber(props.tickerData.acc_trade_value, 2), "")); //props.statusData.updateDetail[4])); don't show volume update
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[5], renderNumber(props.tickerData.units_traded   , 2), props.statusData.updateDetail[5]));
  priceFeedContent.push(renderPriceFeedRows(
    priceFeedTag[6],
    "(" + (renderNumber(props.tickerData.fluctate_24H, 2).charAt(0) === "-" ? "" : "+") + renderNumber(props.tickerData.fluctate_24H, 2) + ") " + (renderNumber(props.tickerData.fluctate_24H, 2).charAt(0) === "-" ? "" : "+") + renderNumber(props.tickerData.fluctate_rate_24H, 2) + "%",
    props.statusData.updateDetail[6],
    props.tickerData.fluctate_24H.charAt(0) === "-" ? "mn" : "pl"
    ),
  );
  priceFeedContent.push(renderPriceFeedRows(priceFeedTag[7], props.statusData.timestamp, props.statusData.updateDetail[7], null));

	return (
    <div className='ts-card-pricefeed'>
      <div className='ts-card-pricefeed-headline'>
        <p className='ts-card-pricefeed-headline-exchange'>{props.headerData.exchange}</p>
        <p className='ts-card-pricefeed-headline-ticker'>  {props.headerData.ticker}-{props.headerData.payment}</p>
      </div>
      <div className='ts-card-pricefeed-content'>
        {priceFeedContent}
      </div>
    </div>
  )
}

//tickerData: [0_EXCHANGE, 1_COIN, 2_PAYMENT, 3_LAST, 4_OPEN, 5_MIN, 6_MAX, 7_VOLUME_UNIT, 8_VOLUME_VALUE, 9_CHANGE_PERCENTAGE, 10_CHANGE_VALUE];
//up-down figmentatiaomn?

TSCardPricefeed.defaultProps = {
  headerData: {
    "exchange" : "Bithumb",
    "ticker"   : "BTC",
    "payment"  : "KRW"
  },
  tickerData: {
    "opening_price"       : "29000000",
    "closing_price"       : "28000000",
    "min_price"           : "28150000",
    "max_price"           : "29318000",
    "units_traded"        : "1424.0333163",
    "acc_trade_value"     : "40800738996.4103",
    "prev_closing_price"  : "29223000",
    "units_traded_24H"    : "3569.83099106",
    "acc_trade_value_24H" : "105701712353.507",
    "fluctate_24H"        : "-2044000",
    "fluctate_rate_24H"   : "-6.68"
  },
  statusData: {
    timestamp: "",
    isOnline: false,
    updateDetail: ["", "", "", "", "", "", "", ""]
  },
  deleteHandler: (e) => {console.log(e)},
}

export default TSCardPricefeed;