import React, { Component } from 'react';

import * as LSM from '../../../../managers/localstorageManager/localstorageManager';

import TSModule from '../../atoms/ts-module/ts-module';
import TSHistoryPairselector from '../../molecules/ts-history-pairselector/ts-history-pairselector';
import TSButton from '../../atoms/ts-btn-simple/ts-btn-simple';
import TSInput from '../../atoms/ts-input/ts-input';

import "./ts-history-control-call.css";

class HistoryControlCall extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputABPData: {}
    }
  }

  smallSubmitHandler = (action, data) => {
    if (action === "PAIR") {
      this.props.submitHandler(action, data);
    } else if (action === "EDIT") {
      this.props.submitHandler(action, {
        target: data,
        input: this.state.inputABPData
      });
    } else if (action === "PAGE") {
      this.props.submitHandler(action, data);
    }
  }

  smallInputHandler = (e) => {
    this.setState({
      inputABPData: e.target.value
    })
  }

  renderNumber = (num, digit) => {
    let tmpDigit = 8;
    if (digit == null) tmpDigit = 8;
    else tmpDigit = digit;
    return Number(num).toLocaleString('en-US', { minimumFractionDigits: tmpDigit, maximumFractionDigits: tmpDigit });
  }

  renderDate = (timestamp) => {
    if (timestamp === undefined) return null;

    const D = new Date((Number(timestamp) / 1000));
    const result = D.toTimeString('ko-KR').substring(0, 8) + "(" + D.toISOString().substring(2, 10) + ")";

    return result;
  }

  render = () => {
    const finalContentPairSelect = (
      <TSHistoryPairselector submitHandler={(data) => { this.smallSubmitHandler("PAIR", data) }} />
    );

    //plan : handle 'update abp' command in the page level, and handle local stroage abp edit command in the organism level
    //edit major data in the organism and page loads from localstroage / calculate / and update

    const finalContentControl = (
      <div className='ts-history-control-form'>
        <div className='ts-history-control-indicator'>
          <p>Selected Pair  &nbsp; <span>{this.props.selectedPairs[0] + this.props.selectedPairs[1]}</span></p>
          <p>Avg. Buy Price &nbsp; <span>{this.renderNumber(this.props.abpData.abp, 2)}</span></p>
          <p>Amount Bought &nbsp;  <span>{this.renderNumber(this.props.abpData.amt, 4)}</span></p>
          <p>Last Trade Time       &nbsp; <span>{this.renderDate(this.props.abpData.timestamp)}</span></p>
        </div>

        <TSInput inputHandler={this.smallInputHandler} />

        <div className='ts-history-control-buttons'>
          <div className='ts-history-control-buttons-left'>
            <TSButton theme="blue" label="Recalc ABP" clickHandler={() => { this.props.recalcHandler() }} />
          </div>
          <div>
            <TSButton theme="dark" label="⚠️Edit ABP" clickHandler={() => { this.smallSubmitHandler("EDIT", "ABP") }} />
          </div>

          <div className='ts-history-control-buttons-left'>
            <TSButton theme="dark" label="⚠️Edit ABP Amt" clickHandler={() => { this.smallSubmitHandler("EDIT", "AMT") }} />
          </div>
          <div>
            <TSButton theme="dark" label="⚠️Edit ABP Time" clickHandler={() => { this.smallSubmitHandler("EDIT", "TIMESTAMP") }} />
          </div>

          <div className='ts-history-control-buttons-left'>
            <TSButton theme="dark" label="< Page Prev" clickHandler={() => { this.smallSubmitHandler("PAGE", -1) }} />
          </div>
          <div>
            <TSButton theme="dark" label="Page Next >" clickHandler={() => { this.smallSubmitHandler("PAGE", 1) }} />
          </div>
        </div>
      </div>
    );

    //modulize and return
    return (
      <div className='ts-history-control-call'>
        <div className='ts-history-control-call-1'>
          <TSModule
            label="Pair Select"
            content={finalContentPairSelect}
          />
        </div>
        <div className='ts-history-control-call-2'>
          <TSModule
            label="Trade History & ABP Control"
            content={finalContentControl}
          />
        </div>
      </div>
    )
  }
}

HistoryControlCall.defaultProps = {
  selectedPairs: ["", ""],
  abpData: { abp: 0, amt: 0, timestamp: 1600000000000 },
  submitHandler: () => { },
  recalcHandler: () => { }
}

export default HistoryControlCall;