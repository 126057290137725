import React from 'react';

import TSLabelSimple from '../../atoms/ts-label-simple/ts-label-simple';

import './ts-sidemenu-account.css';

function TSSidemenuAccount (props) {
	return (
    <div className="ts-sidemenu-account">
      <p>Main Account</p>
      <TSLabelSimple theme="dark" labelText={props.mainAccount}/>
    </div>
  )
}

TSSidemenuAccount.defaultProps = {
  mainAccount: "Main Account Name"
}

export default TSSidemenuAccount;