import React, { Component } from 'react';

import * as LSM from "../../../managers/localstorageManager/localstorageManager";
import * as BAM from "../../../managers/bithumbApiManager/bithumbApiManager";

import AccountdetailContentList from '../../UI/organisms/ts-accountdetail-content-list/ts-accountdetail-content-list';
import AccountdetailControlCall from '../../UI/organisms/ts-accountdetail-control-call/ts-accountdetail-control-call';

import './ts-page-detail.css';

class TSDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      accountData: [],
      changes: [],
      callLimit: 30,
      callLimitInterval: null,
      autoUpdate: false,
      auatoUpdateinterval: null,
    }
  }

  componentDidMount = () => {
    let lsmNum = LSM.getMainAccountNumber();
    let lsmResult = LSM.getAccounts();

    let tmpList = [];
    let tmpData = {};
    
    for(let i = 0; i < lsmResult.length; i++) {
      tmpData = {
        isMain: lsmNum === lsmResult[i].slotId,
        timestamp: "2022-00-00 T09:00:00",
        assetList: [],
        deltaList: [],
        ...lsmResult[i]
      };
      tmpList.push(tmpData);

      //filter "NO ACCOUNT" moved to organisms
    }

    this.setState({
      accountData: tmpList,
      callLimitInterval: setInterval(()=>{this.addCallLimit(1)}, 1000)
    })

    setTimeout(()=>{this.updateBithumbAccount(-1)}, 500)
  }

  componentWillUnmount = () => {
    clearInterval(this.state.callLimitInterval);
    clearInterval(this.state.auatoUpdateinterval);
  }

  toggleAutoUpdater = () => {
    if(this.state.autoUpdate) { //when it's on
      clearInterval(this.state.auatoUpdateinterval);
      this.setState({
        autoUpdate: false,
        auatoUpdateinterval: null
      })
    } else { //when it's off
      this.setState({
        autoUpdate: true,
        auatoUpdateinterval: setInterval(()=>{this.updateBithumbAccount(-1)}, 10000)
      })
    }
  }

  deltaResetter = (id) => {
    let tmpAccountData = JSON.parse(JSON.stringify(this.state.accountData)); //deep copy

    if(id === -1) { //delta reset all (clear all account's delta)
      for(let i = 0; i<tmpAccountData.length; i++) {
        for(let j = 0; j < tmpAccountData[i].assetList.length; j++) {
          tmpAccountData[i].deltaList[j] = false; 
        }
      }
    } else { //delta reset id
      for(let i = 0; i < tmpAccountData[id].assetList.length; i++) {
        tmpAccountData[id].deltaList[i] = false; 
      }
    }
    
    this.setState({
      accountData: tmpAccountData
    })
  }

  useCallLimit = (count) => {
    if(count <= this.state.callLimit && this.state.callLimit > 0) {
      this.setState({
        callLimit: this.state.callLimit - count
      })
      return true;
    } else {
      return false;
    }
  }

  addCallLimit = (count) => {
    this.setState({
      callLimit: (this.state.callLimit + count > 30 ? 30 : this.state.callLimit + count)
    })
  }

  updateBithumbAccount = async(id) => {
    //async call bithumb api and handle the result via callback

    if(id === -1) {
      if(!this.useCallLimit(10)) {
        return false; //limit call
      }

      for(let i = 0; i < this.state.accountData.length; i++) {
        if(this.state.accountData[i].nick !== "NO ACCOUNT") {
          BAM.getAccountBalance(this.state.accountData[i].slotId, "ALL", this.state.accountData[i].con, this.state.accountData[i].sec)
          .then((result) => {
            this.getAccountBalanceHandler(i, result.data, result.time);
            setTimeout(()=>{this.deltaResetter(i)}, 1200)
          })
          .catch((err) => {
            console.log(err);
            setTimeout(()=>{this.deltaResetter(i)}, 1200)
          })
        }
      }
    } else {
      if(!this.useCallLimit(1)) {
        return false; //limit call
      }

      BAM.getAccountBalance(this.state.accountData[id].slotId, "ALL", this.state.accountData[id].con, this.state.accountData[id].sec)
      .then((result) => {
        this.getAccountBalanceHandler(id, result.data, result.time);
        setTimeout(()=>{this.deltaResetter(id)}, 1200)
      })
      .catch((err) => {
        console.log(err);
        setTimeout(()=>{this.deltaResetter(id)}, 1200)
      })
    }

    //setTimeout(()=>{this.deltaResetter(id)}, 1200)
  }

  getAccountBalanceHandler = async (id, data, timestamp) => {
    let finalData = []; 
    let deltaData = [];
    let keys = Object.keys(data);
    
    console.log("API RESPONSE");

    for(let i = 0; i < keys.length; i++) {
      if(Number(data[keys[i]].total) !== 0) {                                  //filter zero
        if(Number(data[keys[i]].total) * Number(data[keys[i]].price) > 100) {  //filter small balance

          //console.log(keys[i]);
          //console.log(data[keys[i]]);
          let tmpABP = {abp: 0, amt: 0, timestamp: 0};

          //console.log(await LSM.getAccountTickerABP(id, keys[i]));
          if(await LSM.getAccountTickerABP(id, keys[i]) !== false) {
            tmpABP = await LSM.getAccountTickerABP(id, keys[i]);
          }
          console.log(tmpABP);

          finalData.push({
            ticker: keys[i],
            ...data[keys[i]],
            abp: tmpABP.abp
          })

          deltaData.push(false);
        }
        else if(keys[i] === "krw") { //catch krw
          data[keys[i]].price = '1';

          finalData.push({
            ticker: keys[i],
            ...data[keys[i]],
            abp: 1,
          })

          deltaData.push(false);
        }
      } else {
        //console.log("SKIP ZERO")
      }
    }

    let tmpAccountData = JSON.parse(JSON.stringify(this.state.accountData)); //deep copy
    tmpAccountData[id].assetList = finalData;
    tmpAccountData[id].deltaList = deltaData;
    tmpAccountData[id].timestamp = timestamp;

    for(let i = 0; i < tmpAccountData[id].assetList.length; i++) {
      if(JSON.stringify(tmpAccountData[id].assetList[i]) !== JSON.stringify(this.state.accountData[id].assetList[i])) { //set true if there's difference
        tmpAccountData[id].deltaList[i] = true;        
      }
    }

    this.setState({
      accountData: tmpAccountData
    })

  }

  render = () => {
    return (
      <div className="ts-page-detail-container">
        <div className="ts-page-detail-content">
          <AccountdetailContentList accountData={this.state.accountData} updater={this.updateBithumbAccount}/>
        </div>
        <div className="ts-page-detail-control">
          <AccountdetailControlCall updater={this.updateBithumbAccount} autoUpdater={this.toggleAutoUpdater} avail={this.state.callLimit} isAutoUpdateOn={this.state.autoUpdate}/>
        </div>
      </div>
    ) 
  }
}

export default TSDetail;