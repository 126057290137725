import React from 'react';
import './ts-card-account.css';

function TSCardAccount (props) {

  //      {JSON.stringify(props.accountData)}

	return (
    <div className={'ts-card-account ' + (props.accountData.isSelected ? "ts-card-account-selected" : "")} onClick={()=>{props.selectAccountHandler(props.accountData.slotId)}}>
      <div className='ts-card-account-data-1'>
        <p>{props.accountData.isSelected ? "🗹" : "☐"}</p>
      </div>
      <div className='ts-card-account-data-2'>
        <p>SLOT {props.accountData.slotId}</p>
      </div>
      <div className='ts-card-account-data-3'>
        <p>{props.accountData.nick}</p>
      </div>
      <div className='ts-card-account-data-4'>
        <p>{props.accountData.con.substr(0, 15) + "***************"}</p>
      </div>
      <div className='ts-card-account-data-5'>
        <p>{props.accountData.sec.substr(0, 15) + "***************"}</p>
      </div>
      <div className='ts-card-account-data-6'>
        <button className={'ts-card-account-setmainbutton ' + (props.accountData.isSelected ? "ts-card-account-setmainbutton-selected " : "") + (props.accountData.isMain ? "ts-card-account-setmainbutton-main " : " ")}
        onClick={()=>{props.selectMainHandler(props.accountData.slotId)}}>
          {props.accountData.isMain ? "✔️ MAIN" : "SET MAIN"}
        </button>
      </div>
    </div>
  )
}

TSCardAccount.defaultProps = {
  accountData: {
    slotId: 0,
    nick: "No Account",
    con: "none",
    sec: "none",
    isSelected: false,
    isMain: false,
  },
  selectAccountHandler: (e) => {console.log(e)},
  selectMainHandler: (e) => {console.log(e)}
}

export default TSCardAccount;